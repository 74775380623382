// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Sans');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~choices.js/src/styles/choices';


.bg-dark-alt {
    background-color: #262c2f;
}


.choices__inner {
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    min-height: 38px;
}

.choices__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 1px;
    min-width: 3ch !important;
    width: 1ch !important;

    .choices__input--cloned {
        min-width: 3ch !important;
        width: 1ch !important;
    }
}

.choices__list--multiple {
    .choices__item {
        display: inline-block;
        vertical-align: middle;
        border-radius: 0.25rem;
        padding: 0px 0.25rem;
        font-size: 0.70rem;
        font-weight: 500;
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        background-color: #0166ff;
        border: 1px solid #0166ff;
        color: #ffffff;
        word-break: break-all;
        box-sizing: border-box;
    }
}


.choices__list--dropdown {
    .choices__item--selectable {
        padding-right: 0;
    }
}


.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #c0c4c7;
}

.chart {
    min-height: 60vh;

    canvas {
        width: 100%;
        height: 60vh;
    }
}
